<template>
  <div class="page-list-content">
    <div class="page-list-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          章管理
        </h2>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button v-on:click="createChapter()" class="button-create-page">
            新規登録
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables :items="listChapter.data" :fields="fields" :itemsPerPage="50">
        <template v-slot:page_content="{ item }">
          <td v-html="item.page_content"></td>
        </template>
        <template v-slot:title="{ item }">
          <td style="min-width: 200px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
        <template v-slot:id_chapter="{ item }">
          <td style="min-width: 200px; width: 20%" class="py-2">
            <div class="text-truncate-mess">
              {{ item.id_chapter }}
            </div>
          </td>
        </template>
        <template v-slot:id="{ index }">
          <td>{{ listChapter.current_page * limit - limit + index + 1 }}</td>
        </template>
        <template v-slot:updated_at="{ item }">
          <td>
            {{
              new Date(item.updated_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item, index }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId
                  ? 'Edit Chapter Managerment'
                  : 'Edit Chapter Managerment domain',
                params: {
                  shopId: shop_id,
                  id: item.id,
                },
                query: {
                  no: listChapter.current_page * limit - limit + index + 1,
                },
              }"
            >
              <CButton
                class="mx-1 text-nowrap"
                color="primary"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-comfirm
              >
                編集
              </CButton>
            </router-link>
            <CButton
              class="mx-1 text-nowrap"
              color="danger"
              square
              v-on:click="showConfirm(item.id)"
              variant="outline"
              size="sm"
              v-b-modal.delete-modal-page
            >
              削除
            </CButton>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listChapter.total"
      >
        <p class="font-weight-bold">
          {{ listChapter.total }}件中{{ listChapter.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listChapter.total"
      >
        <CPagination
          v-if="listChapter.total"
          :activePage.sync="page"
          :pages="listChapter.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-page" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            @click="removeCategory(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-page')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="modal-showContent" hide-header hide-footer>
        <div class="d-flex justify-content-center"></div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "ListChapter",
  components: {
    Tables,
  },
  data() {
    return {
      fields: tableFields.CHAPTER,
      listChecked: [],
      dataModal: "",
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      limit: 50,
      page: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  async created() {
    await this.getListChapters(this.page);
    this.lengthList = this.listChapter.total;
  },
  computed: {
    ...mapGetters(["listChapter", "message", "success", "error"]),
    lengthList() {
      if (this.listChapter) {
        return this.listChapter.total;
      }
      return null;
    },
  },
  watch: {
    success() {
      if (this.success) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        // this.getListCate(this.page);
      }
    },
    error() {
      if (this.error) {
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    // listChapter() {
    //   this.page = this.listChapter.current_page;
    // },
    page() {
      this.getListChapterNews(this.page);
      // await this.getListChapters(this.page);
    },
  },
  methods: {
    ...mapActions({ getListChapterNew: "getListChapterNew" }),
    ...mapActions({ deleteChapter: "deleteChapter" }),
    getListChapters(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
        },
      };
      this.getListChapterNew(formData);
    },
    createChapter() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create Chapter Managerment"
          : "Create Chapter Managerment domain",
        query: { no: this.lengthList + 1 },
      });
    },
    showConfirm(id) {
      this.dataModal = id;
    },
    showConfirmContent(id) {
      this.dataModal = id;
    },
    async removeCategory(id) {
      const data = {
        id: id,
        shop_id: this.shop_id,
      };
      const dataReturn = await this.$store.dispatch("deleteChapter", data);
      if (dataReturn.success) {
        this.$toasted.success(dataReturn.message);
        this.$bvModal.hide("delete-modal-page");
        this.getListChapters(1);
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    editCategory(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Category"
          : "Edit Category domain",
        params: { id: id },
      });
    },
    changeLimitPage() {
      this.getListChapters(1);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
  },
};
</script>
